import React from "react";
import "./ExperienceCard.css";

function ExperienceCard(props) {
  const experience = props.experience;
  const theme = props.theme;
  return (
    <div
      className="experience-card"
      style={{
        // backgroundColor: experience["color"],
      }}
    >

      <div className="experience-card-body-div">
        <div className="experience-card-header-div">
          <div className="experience-card-logo-div">
            <a
              href={experience["company_url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="experience-card-logo"
                src={require(`../../assests/images/${experience["logo_path"]}`)}
                alt=""
              />
            </a>
          </div>
          <div className="experience-card-heading-left">
            <p className="experience-card-company">
              <a
                href={experience["company_url"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {experience["title"]}
              </a>
            </p>
            <p
              className="experience-card-summary"
              style={{ color: theme.secondaryText }}
            >
              {experience["summary"]}
            </p>
          </div>
          <div className="experience-card-heading-right">
            <p
              className="experience-card-duration"
              style={{ color: theme.secondaryText }}
            >
              {experience["duration"]}
            </p>
            <p
              className="experience-card-location"
              style={{ color: theme.secondaryText }}
            >
              {experience["location"]}
            </p>
          </div>
        </div>
        <p
          className="experience-card-description"
          style={{ color: theme.text }}
        >
          {experience["description"].map(exp => <div>
                  {exp}<br/>
                </div>)}
        </p>
      </div>
    </div>
  );
}

export default ExperienceCard;
