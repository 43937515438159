/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  // useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Jun Hao",
  logo_name: "Jun Hao",
  nickname: "Sweet",
  full_name: "Jun Hao",
  subTitle:
    "Experienced Full Stack Developer with a Passion for Innovation.",
  resumeLink:
    "./Resume.pdf",
  mail: "mailto:simmons4681@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/lovelysweet1017",
  linkedin: "https://www.linkedin.com/in/junhao1017/",
  skype: "live:.cid.b4a5d6dadb46e0a4",
  gmail: "simmons4681@gmail.com",
  telegram: "https://t.me/Devstrong",
};


const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing full-stack applications using a range of programming languages",
        "⚡ Building functional web applications using popular frameworks and libraries",
        "⚡ Utilizing effective methodologies and tools for design, development and deploy",
        "⚡ Elevating user experiences, driving business growth through strategic tech solutions",
      ],
      softwareSkills: [
        {
          skillName: "HTML",
          fontAwesomeClassname: "skill-icons:html",
          style: {
            color: "#E44D26",
          },
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "skill-icons:css",
          style: {
            color: "#264DE4",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "skill-icons:javascript",
          style: {
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "skill-icons:typescript",
          style: {
            color: "#007ACC",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "devicon:cakephp",
          style: {
            color: "#777BB4",
          },
        },
        {
          skillName: "R",
          fontAwesomeClassname: "twemoji:letter-r",
          style: {
            color: "#276DC3",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "logos:c-sharp",
          style: {
            color: "#0078D7",
          },
        },
        {
          skillName: "Ruby",
          fontAwesomeClassname: "logos:ruby",
          style: {
            color: "#0078D7",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "#007396",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "devicon:mongodb",
          style: {
            color: "#4DB33D",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "fontisto:mysql",
          style: {
            color: "#00758F",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-dark",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: ".Net",
          fontAwesomeClassname: "skill-icons:dotnet",
          style: {
            color: "#888888",
          },
        },
        {
          skillName: "Node.js",
          fontAwesomeClassname: "devicon:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "devicon:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Vue.js",
          fontAwesomeClassname: "fontisto:vuejs",
          style: {
            color: "#4FC08D",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "devicon:angularjs",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "Next.js",
          fontAwesomeClassname: "skill-icons:nextjs-dark",
          style: {
            color: "#FFFFFF",
          },
        },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "mdi:laravel",
          style: {
            color: "#FF2D20",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "skill-icons:django",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#1ABC9C",
          },
        },
        {
          skillName: "Pytorch",
          fontAwesomeClassname: "devicon:pytorch",
          style: {
            color: "#EE4C2C",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos:tensorflow",
          style: {
            color: "#FF6F00",
          },
        },
        {
          skillName: "OpenCV",
          fontAwesomeClassname: "devicon:opencv",
          style: {
            color: "#5C3EE8",
          },
        },
        {
          skillName: "WordPress",
          fontAwesomeClassname: "skill-icons:wordpress",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "logos:firebase",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: "Photoshop",
          fontAwesomeClassname: "iconoir:adobe-photoshop",
          style: {
            color: "#31A8FF",
          },
        },
        {
          skillName: "Agile/Scrum",
          fontAwesomeClassname: "iconoir:agile",
          style: {
            color: "#6CBEEF",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "devicon:git",
          style: {
            color: "#888888",
          },
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "mdi:github",
          style: {
            color: "#888888",
          },
        },
        {
          skillName: "Thingsboard",
          fontAwesomeClassname: "eos-icons:iot",
          style: {
            color: "#FFFF00",
          },
        },
        {
          skillName: "Matlab",
          fontAwesomeClassname: "vscode-icons:file-type-matlab",
          style: {
            color: "#888888",
          },
        },
        {
          skillName: "Arduino",
          fontAwesomeClassname: "skill-icons:arduino",
          style: {
            color: "#888888",
          },
        },
        {
          skillName: "RaspberryPi",
          fontAwesomeClassname: "logos:raspberry-pi",
          style: {
            color: "#888888",
          },
        },
      ],
    },
    // {
    //   title: "Blockchain Development",
    //   fileName: "BlockchainImg",
    //   skills: [ 
    //     "⚡ Developing blockchain solutions using various programming languages",
    //     "⚡ Utilizing advanced development tools and frameworks",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Solidity",
    //       fontAwesomeClassname: "skill-icons:solidity",
    //       style: {
    //         color: "#0066CC"
    //       },
    //     },
    //     {
    //       skillName: "Solana",
    //       fontAwesomeClassname: "mingcute:solana-sol-fill",
    //       style: {
    //         color: "#0088CC"
    //       },
    //     },
    //     {
    //       skillName: "JavaScript",
    //       fontAwesomeClassname: "skill-icons:javascript",
    //       style: {
    //         color: "#F7DF1E",
    //       },
    //     },
    //     {
    //       skillName: "C++",
    //       fontAwesomeClassname: "file-icons:c",
    //       style: {
    //         color: "#662996",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "logos:python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Java",
    //       fontAwesomeClassname: "logos:java",
    //       style: {
    //         color: "#007396",
    //       },
    //     },
    //     {
    //       skillName: "Go",
    //       fontAwesomeClassname: "skill-icons:golang",
    //       style: {
    //         color: "#00ADD8",
    //       },
    //     },
    //     {
    //       skillName: "Web3.js",
    //       fontAwesomeClassname: "logos:web3js",
    //       style: {
    //         color: "#F16822",
    //       },
    //     },
    //     {
    //       skillName: "Ether.js",
    //       fontAwesomeClassname: "logos:ethers",
    //       style: {
    //         color: "#3C3C3D",
    //       },
    //     },
    //     {
    //       skillName: "Fabric",
    //       fontAwesomeClassname: "logos:fabric",
    //       style: {
    //         color: "#0B3D91",
    //       },
    //     },
    //     {
    //       skillName: "Ethereum",
    //       fontAwesomeClassname: "logos:ethereum-color",
    //       style: {
    //         color: "#339933",
    //       },
    //     },
    //     {
    //       skillName: "Truffle",
    //       fontAwesomeClassname: "logos:truffle-icon",
    //       style: {
    //         color: "#7B43A1",
    //       },
    //     },
    //     {
    //       skillName: "Remix",
    //       fontAwesomeClassname: "skill-icons:remix-dark",
    //       style: {
    //         color: "#4CAF50",
    //       },
    //     },
    //     {
    //       skillName: "Ganache",
    //       fontAwesomeClassname: "logos:ganache-icon",
    //       style: {
    //         color: "#FFA726",
    //       },
    //     },
    //     {
    //       skillName: "Metamask",
    //       fontAwesomeClassname: "logos:metamask-icon",
    //       style: {
    //         color: "#E2761B",
    //       },
    //     },
    //   ],
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "The University of Hong Kong",
      subtitle: "B.S. in Computer Science",
      logo_path: "cuhk.png",
      alt_name: "HKU",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ Won Merit and Leadership scholarships",
        "⚡ Landed an part-time job in my field of study",
        "⚡ Graduated with Summa Cum Laude",
      ],
      website_link: "https://www.cuhk.edu.hk/english/index.html",
    }
  ],
};

const certifications = {
  certifications: [
    {
      title: "Responsive Web Design",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/responsive-web-design",
      alt_name: "FreeCodeCamp",
      color_code: "#EFE1CE",
    },
    {
      title: "Javascript Algorithms and Data Structures",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/javascript-algorithms-and-data-structures",
      alt_name: "FreeCodeCamp",
      color_code: "#0072B5",
    },
    {
      title: "Front End Development Libraries",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/front-end-development-libraries",
      alt_name: "FreeCodeCamp",
      color_code: "#E3405F",
    },
    {
      title: "Data Visualization",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/data-visualization",
      alt_name: "FreeCodeCamp",
      color_code: "#B55A30",
    },
    {
      title: "Legacy Front End",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/legacy-front-end",
      alt_name: "FreeCodeCamp",
      color_code: "#9BB7D4",
    },
    {
      title: "Legacy Data Visualization",
      subtitle: "FreeCodeCamp",
      logo_path: "fcc/white.png",
      certificate_link: "https://www.freecodecamp.org/certification/fccb2a88230-af40-48bc-9d8e-67b5859abe60/legacy-data-visualization",
      alt_name: "FreeCodeCamp",
      color_code: "#939597",
    },
    {
      title: "Angular (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/83617ac169f3",
      alt_name: "HackerRank",
      color_code: "#EFE1CE",
    },
    {
      title: "Angular (Intermediate)",
      subtitle: "HackerRank",
      logo_path: "hrtest/burgundy.png",
      certificate_link: "https://www.hackerrank.com/certificates/8977103a6b28",
      alt_name: "HackerRank",
      color_code: "#EFE1CE",
    },
    {
      title: "C# (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/f622785e743b",
      alt_name: "HackerRank",
      color_code: "#0072B5",
    },
    {
      title: "Java (Basic)",
      subtitle: "HakerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/b71212cabda9",
      alt_name: "HakerRank",
      color_code: "#E3405F",
    },
    {
      title: "JavaScript (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/4dfe671de9f7",
      alt_name: "HackerRank",
      color_code: "#9BB7D4",
    },
    {
      title: "JavaScript (Intermediate)",
      subtitle: "HackerRank",
      logo_path: "hrtest/burgundy.png",
      certificate_link: "https://www.hackerrank.com/certificates/855b67e8d96f",
      alt_name: "HackerRank",
      color_code: "#9BB7D4",
    },
    {
      title: "Problem Solving (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/c39f6488000d",
      alt_name: "HackerRank",
      color_code: "#939597",
    },
    {
      title: "Python (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/599c7e44657c",
      alt_name: "HackerRank",
      color_code: "#0072B5",
    },
    {
      title: "R (Intermediate)",
      subtitle: "HackerRank",
      logo_path: "hrtest/burgundy.png",
      certificate_link: "https://www.hackerrank.com/certificates/6d9fa1c42ec8",
      alt_name: "HackerRank",
      color_code: "#FFDB58",
    },
    {
      title: "React (Basic)",
      subtitle: "HackerRank",
      logo_path: "hrtest/white.png",
      certificate_link: "https://www.hackerrank.com/certificates/f055edb0320d",
      alt_name: "HackerRank",
      color_code: "#B55A30",
    },
    // {
    //   title: "SQL (Basic)",
    //   subtitle: "HackerRank",
    //   logo_path: "hrtest/white.png",
    //   certificate_link: "https://www.hackerrank.com/certificates/cf6403bf3020",
    //   alt_name: "HackerRank",
    //   color_code: "#FFFFE0",
    // },
    {
      title: "SQL (Intermediate)",
      subtitle: "HackerRank",
      logo_path: "hrtest/burgundy.png",
      certificate_link: "https://www.hackerrank.com/certificates/e3f9f04ca22a",
      alt_name: "HackerRank",
      color_code: "#FFFFE0",
    },
    {
      title: "SQL (Advanced)",
      subtitle: "HackerRank",
      logo_path: "hrtest/charcoal.png",
      certificate_link: "https://www.hackerrank.com/certificates/566cb0ab1bf3",
      alt_name: "HackerRank",
      color_code: "#FFFFE0",
    },
  ],
};

// Experience Page
const experience = {
  title: "Professional Experience",
  subtitle: "Full-stack Developer with 6+ years of experience",
  description: [
    "Awesome Work and Ontime Delivery. Good support received from JunHao.",
    "JunHao has an exceptional troubleshooting and analytical skills in WEB.",
    "JunHao is a great asset to any company. - Ciaran Lee, CTO of Intercom.",
  ],
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Full Stack Developer",
      experiences: [
        {
          title: "Intercom",
          company: "Intercom",
          company_url: "https://www.intercom.com/careers/dublin",
          logo_path: "intercom1.png",
          duration: "March 2021 - May 2023",
          location: "Dublin, Ireland (Remote)",
          summary:
            " Intercom is a customer relationship management (CRM) platform that helps businesses engage with their customers through messaging, in-app chat, and email. ",
          description:
            ["⚡ Develop and maintain 20+ web applications using ReactJS and Node.js, resulting in 20% increased user engagement and 15% increase in revenue growth",
            "⚡ Write 10,000+ lines of clean, efficient, and well-documented code, ensuring maintainability and scalability of the applications",
            "⚡ Participate in 50+ code reviews, ensuring 100% code quality, and facilitating a culture of excellence and continuous improvement",
            "⚡ Implement automated testing and continuous integration practices, resulting in a 25% reduction in release time and increased productivity",
            "⚡ Deliver all projects on-time and under budget, resulting in a 95% customer satisfaction rating and repeat business opportunities"
            ],
          color: "#7F001F",
        },
      ],
    },
    {
      title: "Full Stack Developer",
      experiences: [
        {
          title: "Goxip Inc",
          company: "Goxip Inc",
          company_url: "https://www.goxip.com/",
          logo_path: "goxip.png",
          duration: "June 2018 - February 2021",
          location: "Hong Kong",
          summary:
            " Goxip is a fashion and beauty discovery app based in Hong Kong. Goxip allows users to discover new fashion and beauty products by scanning images of celebrities, influencers, and other users.",
          description:
            ["⚡ Contribute to the development of a large-scale e-commerce platform using ReactJS and Ruby on Rails, resulting in a 25% increase in user experience and a 20% increase in sales revenue ",
            "⚡ Work closely with 6 designers, 3 product managers, and 10 other developers to deliver 35 high-quality features on time, ensuring customer satisfaction and retention",
            "⚡ Implement various features such as payment gateway integration, order management system, and user authentication, resulting in a seamless shopping experience for customers",
            "⚡ Achieve a 99.9% uptime rate, reducing downtime incidents by 60%, ensuring high availability and reliability of the platform ",
            "⚡ Successfully implement agile methodologies for project management, resulting in a 30% increase in development speed and improved team collaboration",
          ],
          color: "#35444E",
        },
      ],
    },
    {
      title: "Junior Full Stack Developer",
      experiences: [
        {
          title: "AIA Group Limited",
          company: "AIA Group Limited",
          company_url: "https://www.aia.com/en",
          logo_path: "aia_group.png",
          duration: "July 2017 - May 2018",
          location: "Hong Kong",
          summary:
            " AIA Group Limited is a Hong Kong-based multinational insurance and financial services corporation. It is the largest publicly listed life insurance group in Asia-Pacific.",
          description:
            ["⚡ Develop and maintain a suite of 15 internal web applications using AngularJS and Java Spring Framework, resulting in a 15% increase in operational efficiency and productivity",
            "⚡ Work with 20 stakeholders to understand business requirements and translate them into technical solutions, ensuring alignment with organizational goals",
            "⚡ Optimize server response time by 40%, resulting in an improved user experience for all applications and increased user satisfaction.",
          ],
          color: "#35444E",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects have resulted in increased user engagement, revenue growth, and operational efficiency. I have also implemented various features such as payment gateway integration, order management system, and user authentication, resulting in a seamless shopping experience for customers. Additionally, I have achieved high availability and reliability of the platforms with a 99.9% uptime rate and reduced downtime incidents by 60%. I have successfully implemented agile methodologies for project management, resulting in a 30% increase in development speed and improved team collaboration.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      name: "Intercom Journey",
      url: "https://www.intercom.com/product-tours",
      description:
        "Customer onboarding and engagement tool that helps businesses guide users through their product.",
      stack: ["HTTP/3", "React", "Next.js"],
    },
    {
      name: "Intercom Conversations",
      url: "https://www.intercom.com/app-store?category=conversation-management",
      description:
        "Customer messaging platform that allows businesses to communicate with their customers in real time.",
      stack: ["Ember.js", "React", "Nuxt.js"],
    },
    {
      name: "Online TV Shows",
      url: "https://bertflix.vercel.app/",
      description:
        "Watch TV shows Online, Watch Movie Online",
      stack: ["Node.js", "React.js", "TypeScript"],
    },
    {
      name: "Goxip Flash Sale",
      url: "https://theflashsale.com/",
      description:
        "Daily event where select products are discounted for a limited time. Great way to find great deals on high-quality products.",
      stack: ["Angular", "Node.js", "Express.js", "TypeScript"],
    },
    {
      name: "Shop Live",
      url: "https://www.goxip.com/gs/en/designers/live",
      description:
        "Goxip offers live shopping events, where users can interact with brands and influencers in real time.",
      stack: ["Vue.js", "Bootstrap", "jQuery"],
    },
    {
      name: "Harbour City",
      url: "https://www.harbourcity.com.hk/en/",
      description: "One of the largest shopping malls in Hong Kong",
      stack: ["PHP", "MySQL", "WordPress", "jQuery"],
    },
    {
      name: "AIA Vitality",
      url: "https://www.aia.com/en/health-wellness/vitality",
      description:
        "Health and wellness program that helps people live healthier lives",
      stack: ["JavaScript", "Bootstrap", "jQuery"],
    },
    {
      name: "Devilbox",
      url: "http://devilbox.org/",
      description:
        "Docker LAMP development stack with valid https support",
      stack: ["Angular", "Node", "jQuery"],
    },
    {
      name: "AIA Digital Assistant",
      url: "https://www.globaldata.com/store/report/aia-group-enterprise-tech-analysis/",
      description:
        "Chatbot that helps customers with their insurance needs",
      stack: ["WordPress", "PHP", "MySQL"],
    },
    {
      name: "ZJON",
      url: "https://www.zjson.net/",
      description:
        "Powerful tool that can be used to format, minify, and pretty print JSON data",
      stack: ["Angular","Node","TypeScript"],
    },
    {
      name: "Healthchecks",
      url: "https://healthchecks.io/",
      description:
        "Service that helps you monitor your applications and services. ",
      stack: ["Python", "Django", "PostreSQL"],
    },
    {
      name: "Ovolo Hotels",
      url: "https://ovolohotels.com/",
      description:
        "Innovative and engaging booking experience for customersg",
      stack: ["PHP", "MySQL", "WordPress", "jQuery"],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  projects,
};
